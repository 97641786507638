import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-light-3 px-5 py-5 rounded-xl" }
const _hoisted_2 = { class: "flex flex-wrap md:flex-nowrap justify-between items-start" }
const _hoisted_3 = { class: "flex items-center justify-start text-sm font-bold leading-6 py-2" }
const _hoisted_4 = { class: "flex flex-1 leading-6 pl-3 py-2 items-center justify-start text-sm" }
const _hoisted_5 = { class: "flex items-center py-2 ml-6 text-sm leading-6" }
const _hoisted_6 = { class: "font-medium" }
const _hoisted_7 = { class: "flex flex-col mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_icon, {
          name: "Users",
          class: "mr-3",
          size: "5"
        }),
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", {
          class: "flex justify-center items-center cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapseCard && _ctx.toggleCollapseCard(...args)))
        }, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(!_ctx.cardCollapsed ? "general.show" : "general.hide")) + " " + _toDisplayString(_ctx.title), 1),
          (!_ctx.cardCollapsed)
            ? (_openBlock(), _createBlock(_component_app_icon, {
                key: 0,
                size: "3",
                class: "pl-2",
                name: "ChevronDown"
              }))
            : (_openBlock(), _createBlock(_component_app_icon, {
                key: 1,
                size: "3",
                class: "pl-2",
                name: "ChevronUp"
              }))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "content")
    ])
  ]))
}