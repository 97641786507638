
//@ts-nocheck
import { computed, defineComponent, ref, watch } from "vue";
import AppSection from "@/components/common/AppSection.vue";
import store from "@/store";
import { useStore } from "vuex";
import Checkbox from "primevue/checkbox";
import moment from "moment";
import { ContactsState } from "@/store/types";
import Paginator from "primevue/paginator";
import axios from "axios";
import AppSearch from "@/components/common/AppSearch.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import ContactModel from "@/models/ContactModel";
import CardFilter from "@/components/card/CardFilter.vue";
import AppButton from "@/components/common/AppButton.vue";
import JsonExcel from "vue-json-excel3";
import LeadStatModel from "@/models/LeadStatModel";

export default defineComponent({
    name: "ContactsListPage",
    components: {
        AppButton,
        CardFilter,
        AppSearch,
        AppSection,
        Checkbox,
        Paginator,
        AppIcon,
        downloadExcel: JsonExcel
    },
    async beforeRouteEnter(to, from, next) {
        // If we already have contacts, we reload in the background
        if (store.state.contacts.total === 0) {
            store.commit("app/SET_LOADING", true);
            const params = {
                limit: 1,
                start: 0,
                search: "!is:anonymous"
            };
            await store.dispatch("contacts/fetchList", params).then(() => {
                store.commit("app/SET_LOADING", false);
            });
        }
        store.dispatch("segments/fetchSegments");
        next();
    },
    methods: {
        date2relative(timestamp: number) {
            return timestamp ? moment(timestamp).fromNow() : "never";
        }
    },
    setup() {
        const store = useStore();

        const loadingContacts = ref(false);
        const dynamicContactsSearch = ref("");
        const segmentFilterSearch = ref("");
        const totalContacts = computed(
            (): ContactsState => store.state.contacts.total
        );
        let timeout: NodeJS.Timeout;

        const contactFirst = ref(0);
        const rows = ref(50);
        const contactsCounts = ref(0);

        const dynamicContactList = ref([]);
        const leadStats = ref([]);

        const getLeadStats = () => {
            timeout = setTimeout(() => {
                const params = {
                    limit: rows.value + 1,
                    "where[0][col]": "lead",
                    "where[0][expr]": "in",
                    "where[0][val]": dynamicContactList.value
                        .map((contact: ContactModel) => contact.id)
                        .join(",")
                };

                axios
                    .get("api/ecommerce/leadstats", { params: params })
                    .then(response => {
                        leadStats.value = Object.values(
                            response.data.leadstats
                        );
                    });
            }, 500);
        };
        const getLeadStat = (id: number, valName: string) => {
            const leadStat = leadStats.value.find(
                (leadStat: LeadStatModel) => leadStat.lead.id === id
            );
            return leadStat ? leadStat[valName] : null;
        };

        const getDynamicContactList = () => {
            loadingContacts.value = true;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                const params = {
                    limit: rows.value,
                    start: contactFirst.value,
                    search: `${dynamicContactsSearch.value ??
                        ""} ${segmentFilterSearch.value ??
                        ""} !is:anonymous`.trim()
                };

                axios
                    .get("api/ecommerce/contacts", { params: params })
                    .then(response => {
                        contactsCounts.value = parseInt(response.data.total);

                        dynamicContactList.value = Object.values(
                            response.data.contacts
                        );

                        dynamicContactList.value = dynamicContactList.value
                            .filter(contact => contact.email !== null)
                            .sort(
                                (a, b) =>
                                    (b.lastActive
                                        ? moment(b.lastActive).valueOf()
                                        : 0) -
                                    (a.lastActive
                                        ? moment(a.lastActive).valueOf()
                                        : 0)
                            )
                            .map((contact: ContactModel) => ({
                                ...contact,
                                fullName:
                                    contact.firstname || contact.lastname
                                        ? `${contact.firstname ??
                                              ""} ${contact.lastname ??
                                              ""}`.trim()
                                        : contact.email
                            }));

                        loadingContacts.value = false;
                        getLeadStats();
                    });
            }, 500);
        };

        getDynamicContactList();

        const selectedContacts = ref([]);
        const selectAllContacts = ref(false);

        watch(dynamicContactsSearch, () => {
            selectedContacts.value = [];
            selectAllContacts.value = false;
            getDynamicContactList();
        });

        watch(selectAllContacts, val => {
            if (val) {
                selectedContacts.value = dynamicContactList.value.map(
                    contact => contact.id
                );
            } else {
                selectedContacts.value = [];
            }
        });

        watch(rows, () => {
            selectAllContacts.value = false;
            selectedContacts.value = [];
            getDynamicContactList();
        });

        watch(contactFirst, () => {
            selectAllContacts.value = false;
            selectedContacts.value = [];
            getDynamicContactList();
        });

        const contactFirstFooter = computed(() =>
            contactFirst.value === 0 ? 1 : contactFirst.value
        );
        const contactEndFooter = computed(() =>
            contactsCounts.value >= contactFirst.value + rows.value
                ? contactFirst.value + rows.value
                : contactsCounts.value
        );

        const currency = computed(() => store.state.store.currency);

        const openContactDetails = contactId => {
            store.dispatch("auth/openContactDetails", contactId);
        };

        const segmentFilterCollapsed = ref(true);
        const toggleSegmentFilterCollapsed = () => {
            segmentFilterCollapsed.value = !segmentFilterCollapsed.value;
        };

        const activeSegmentFilter = ref("");
        const applySegmentFilter = (segment: string) => {
            if (segment === activeSegmentFilter.value) {
                activeSegmentFilter.value = "";
                segmentFilterSearch.value = "";
            } else {
                activeSegmentFilter.value = segment;
                segmentFilterSearch.value = `segment:"${segment}"`;
            }
            getDynamicContactList();
        };

        const userSegments = computed(
            (): SegmentsState => store.getters["segments/enabledUserList"]
        );
        const systemSegments = computed(
            (): SegmentsState => store.getters["segments/enabledSystemList"]
        );

        return {
            dynamicContactList,
            selectedContacts,
            selectAllContacts,
            loadingContacts,
            totalContacts,
            dynamicContactsSearch,
            currency,
            contactFirst,
            rows,
            contactFirstFooter,
            contactEndFooter,
            contactsCounts,
            openContactDetails,
            segmentFilterCollapsed,
            toggleSegmentFilterCollapsed,
            userSegments,
            systemSegments,
            applySegmentFilter,
            activeSegmentFilter,
            getLeadStat
        };
    }
});
