
import { defineComponent, ref } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "CardFilter",
    components: {
        AppIcon
    },
    props: {
        title: {
            type: String,
            required: true
        }
    },
    emits: ["collapse"],
    setup(props, { emit }) {
        const cardCollapsed = ref(false);

        const toggleCollapseCard = () => {
            cardCollapsed.value = !cardCollapsed.value;
            emit("collapse");
        };

        return {
            cardCollapsed,
            toggleCollapseCard
        };
    }
});
